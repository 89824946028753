@import "./theme";
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

// All classes in this file MUST be overrides of existing bootstrap classes or tags.

//= Typography

h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 40px;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -0.02em;
  color: $black;
}

h1, h2, h3, h4, h5 {
  margin-bottom: 16px;
}

//= Buttons

.btn {
  font-size: 14px;
  padding: 12px 16px 10px;
}

.btn-primary {
  background-color: $blue;
  border-color: $blue-dark;
  color: $white;

  &:hover, &:focus, &:active, &.active {
    background-color: $blue-medium;
    border-color: $blue-dark;
    color: $white;
  }

  &[disabled], &:disabled, &.disabled {
    color: $white;
    background-color: $medium-gray;
    border-color: $text-gray;
  }
}

.btn-secondary {
  background-color: $white;
  border-color: $blue-dark;
  color: $blue;

  &:hover, &:focus, &:active, &.active {
    text-decoration: underline;
  }

  &[disabled], &:disabled, &.disabled {
    color: $accent-gray;
    background-color: $white;
    border-color: $accent-gray;
  }
}

.btn-success {
  background-color: $green;
  border-color: $green-dark;
  color: $white;

  &:hover, &:focus, &:active, &.active {
    background-color: $green-medium;
    border-color: $green-dark;
    text-decoration: underline;
  }

  &[disabled] {
    color: $white;
    background-color: $medium-gray;
    border-color: $text-gray;
  }
}

.btn-danger {
  background-color: $crimson;
  border-color: $crimson-dark;
  color: $white;

  &:hover, &:focus, &:active, &.active {
    background-color: $crimson-medium;
    border-color: $crimson-dark;
    text-decoration: underline;
  }

  &[disabled] {
    color: $white;
    background-color: $medium-gray;
    border-color: $text-gray;
  }
}

.dropdown-item:hover {
  background-color: $dark-blue;
}

//= Cards

.card-header {
  background-color: $catskill-white;
}

.card-footer {
  background-color: $catskill-white;
}

//= Components

.form-control {
  height: 40px;
}

.input-group {
  .form-control {
    height: 43.5px;
  }
}

.pagination {
  margin-bottom: 0rem;
}

.pagination .page-item {
  margin-left: 0.125rem;
  margin-right: 0.125rem;

  .page-link {
    border-color: $medium-gray;
    color: $medium-gray;
  }
}

.pagination .page-item.active {
  background-color: $blue;
  border-color: $blue-dark;

  .page-link {
    background-color: $blue;
    color: $white;
  }
}

custom-select {
  background-image: none;
}

//= Tables

.table {
  font-size: 16px !important;
}

.table {
  th, thead {
    border: none !important;
  }
}

.table-bordered {
  border: none !important;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: $light-gray;
}

.table tr th:first-child {
  padding-left: 1rem;
}

.table tr td:first-child {
  padding-left: 1rem;
}

.table tr th .fa.fa-caret-down {
  margin-left: 0.5rem;
}

.table tr th .fa.fa-caret-up {
  margin-left: 0.5rem;
}

.table tr td {
  vertical-align: middle;
}

.table tr th.sortable-header--asc::after {
  content: "\f0d8";
  font-family: "Font Awesome 5 Free";
  padding-left: 4px;
  font-size: 12px;
}

.table tr th.sortable-header--desc::after {
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  padding-left: 4px;
  font-size: 12px;
}

//= Dropdown

// Hide the dropdown arrow on all dropdowns except buttons
.dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  cursor: pointer;
}

.btn.dropdown-toggle::after {
  display: inline-block;
}

.btn.btn-outline-primary.dropdown-toggle {
  background-color: white !important;
  border-color: $gray-300;
  color: $text-gray !important;
  padding-right: 2rem;
}

.btn.btn-outline-primary.dropdown-toggle::after {
  color: $blue;
  position: absolute;
  right: 5%;
  top: 1.1em;
}

.btn-outline-primary {
  background-color: white;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: $blue;
  text-decoration: none;
  border-color: $blue;
}

.btn-outline-light {
  color: #2D2D2D;
  font-weight: normal;
  font-size: 14px;
  padding: 8px 16px 8px 16px;
  border-color: #DEDEDE;
  background-color: white;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-outline-light:hover {
  color: #2D2D2D;
  background-color: #b3b3b380;
  text-decoration: none;
  border-color: #DEDEDE;
}

ul {
  list-style-type: none;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: $green !important;
}

//= Inputs

.form-label {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 800;
}

.field {
  margin-bottom: 32px;
}

.form-control:focus {
  box-shadow: 0 0 5px $blue;
  border: 1px solid $blue;
}

//= Alerts & Errors

.alert {
  border-radius: 3px;
}

.alert-primary {
  background-color: $blue-light;
  border-color: $blue;
  color: $blue;
}

.alert-success {
  background-color: #E4FFE7;
  border-color: $green-medium;
  color: $green-medium;
}

.alert-danger {
  background-color: #FFE4E4;
  border-color: $crimson-medium;
  color: $crimson-medium;
}

.alert-warning {
  background-color: $gold-light;
  border-color: $gold-dark;
  color: $gold-dark;
}

.alert-info {
  background-color: $glacier-light;
  border-color: $glacier;
  color: $glacier;
}

.invalid-feedback {
  font-size: 14px;
  color: #DD272D;
}
